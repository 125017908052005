/**
 *
 * Custom configuration for Cargolift organization
 *
 *            **WARNING**
 * Make sure to make changes in custom folder
 * Changes made in source will be ignored
 * @see /custom/giro/config.js
 *
 */

const localization = {
    i18n: {
       /**
         * Should reference atribute defined in i18n resources and import json file
         * @example import pt_BR from '../i18n/custom_organization/pt-br.json';
         * @see /src/config/i18n.ts
         *
         * Should use hifen if need, avoid underscore
         * @example pt-BR instead of pt_BR
         * @link https://github.com/i18next/i18next/issues/1061#issuecomment-395528467
         */
        default_language: 'pt-BR'
    },
    moment: {
        /**
         * Should reference valid moment's locale directory name and import locale
         * @example
         * import 'moment/locale/pt-br';
         * @see /src/shared/util/format-utils.ts
         * @link https://www.ge.com/digital/documentation/predix-services/c_custom_locale_support.html#concept_uly_5xv_l1b__section_w2b_qwy_m1b
         */
        locale: 'pt-br'
    },
    Intl: {
        /**
         * Should reference valid IntL`s locale key
         * @see /src/shared/util/format-utils.ts
         */
        locale: 'pt-BR',
        /**
         * Should reference valid IntL`s currency key
         * @see /src/shared/util/format-utils.ts
         */
        currency: 'BRL'
    }
};

const contact = {
    phone: '(41) 98875-4600'
};

const format = {
    phone: {
        regex: /^[1-9]{2}\d?\d{8}$/
    },
    identification: {
        individual: {
            regex: /(\d{3})(\d{3})(\d{3})(\d{2})/g,
            mask: '$1.$2.$3-$4'
        },
        corporation: {
            regex: /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
            mask: '$1.$2.$3/$4-$5'
        }
    }
};

const configuration = {
    localization,
    contact,
    format
};

export default configuration;
